import { createSelector } from "reselect";
import orderByPosition from "../utils/orderByPosition";

const getBanners = (state) => state.entities.banners;

export const selectAllBanners = createSelector(getBanners, (banners) => {
  return Object.values(banners).sort(orderByPosition);
});

export const selectHomePageBanners = createSelector(
  selectAllBanners,
  (banners) =>
    banners.filter((banner) => banner.displayLocation === "home_page")
);

export const selectStorePageBanners = createSelector(
  selectAllBanners,
  (banners) =>
    banners.filter((banner) => banner.displayLocation === "store_page")
);
