import React from "react";
import Banners from "../banners/Banners";
import CoursesData from "../courses/CoursesData";
import { Page, spacing } from "../library";

const bannersWrapper = {
  marginBottom: spacing.l,
};

export default function Store(props) {
  const { banners } = props;

  return (
    <Page title="Loja">
      {!!banners.length && (
        <Banners wrapperClass={bannersWrapper} banners={banners} />
      )}

      <CoursesData justNotOwned {...props} />
    </Page>
  );
}
