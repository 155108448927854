import { css } from "glamor";
import React, { useMemo } from "react";
import { colors } from "../library";
import { isToday } from "../../utils/isToday";
import { weekdayOptionClass } from "./useWeekdayOption";

const containerClass = css({
  display: "flex",
  flexWrap: "nowrap",
  justifyContent: "space-between",
  flex: 1,
  width: "100%",
  "@media (min-width: 1300px)": {
    maxWidth: 350,
  },
});

const weekdayBlockClass = {
  ...weekdayOptionClass,
  width: 40,
  height: 40,
  color: colors.greyDarker,
  cursor: "default",
  backgroundColor: "rgba(189, 195, 199, 0.2)",
};

const weekdayStatusesStyle = {
  selectedDay: { backgroundColor: "white" },
  completed: {
    color: "#1DB736",
    backgroundColor: "#C1FFCB",
    borderColor: "#72DF84",
  },
  pending: {
    color: "#009EDE",
    backgroundColor: "#D3EFFB",
    borderColor: "#009EDE",
  },
  failed: {
    color: "#DF4D5F",
    backgroundColor: "#F8D5D5",
    borderColor: "#DF4D5F",
  },
};

function weekdayStatus(weekday) {
  const dayStudyGoal = weekday?.dayStudyGoalsAttributes?.at(-1);
  const isDayStudyGoalCreatedToday = isToday(dayStudyGoal?.createdAt);

  if (dayStudyGoal?.status === "failed") return "failed";
  if (dayStudyGoal?.status === "completed") return "completed";
  if (isDayStudyGoalCreatedToday && dayStudyGoal?.status === "pending")
    return "pending";
  if (weekday) return "selectedDay";
}

const weekdayClass = (weekday) =>
  css(weekdayBlockClass, weekdayStatusesStyle[weekdayStatus(weekday)]);

export default function WeekdayStudyGoals({ weekdays }) {
  const { sunday, monday, tuesday, wednesday, thursday, friday, saturday } =
    weekdays;

  const sundayClass = useMemo(() => weekdayClass(sunday), [sunday]);
  const mondayClass = useMemo(() => weekdayClass(monday), [monday]);
  const tuesdayClass = useMemo(() => weekdayClass(tuesday), [tuesday]);
  const wednesdayClass = useMemo(() => weekdayClass(wednesday), [wednesday]);
  const thursdayClass = useMemo(() => weekdayClass(thursday), [thursday]);
  const fridayClass = useMemo(() => weekdayClass(friday), [friday]);
  const saturdayClass = useMemo(() => weekdayClass(saturday), [saturday]);

  return (
    <div className={containerClass}>
      <div className={sundayClass}>D</div>
      <div className={mondayClass}>S</div>
      <div className={tuesdayClass}>T</div>
      <div className={wednesdayClass}>Q</div>
      <div className={thursdayClass}>Q</div>
      <div className={fridayClass}>S</div>
      <div className={saturdayClass}>S</div>
    </div>
  );
}
