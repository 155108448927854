import React, { useEffect } from "react";
import { Spinner } from "../library";
import { Form } from "../library/forms";
import PaymentMethodBankSlip from "./PaymentMethodBankSlip";
import PaymentMethodCreditCard from "./PaymentMethodCreditCard";
import PaymentMethodPix from "./PaymentMethodPix";
import RouteLeavingGuard from "../library/RouteLeavingGuard";
import BillingTypeField from "./BillingTypeField";
import { useDispatch } from "react-redux";
import { change } from "redux-form";

const CREDIT_CARD = "CREDIT_CARD";
const BOLETO = "BOLETO";
const PIX = "PIX";

const formStyle = {
  flex: 1,
  display: "flex",
  flexDirection: "column",
  height: "100%",
};
const formWrapperStyle = {
  height: "100%",
};
const spinnerWrapper = {
  flex: 1,
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
};

const getPaymentMethodFormFields = (props) => {
  switch (props?.billingType) {
    case BOLETO:
      return <PaymentMethodBankSlip {...props} />;
    case CREDIT_CARD:
      return <PaymentMethodCreditCard {...props} />;
    case PIX:
      return <PaymentMethodPix {...props} />;
    default:
      return null;
  }
};

export default function PaymentForm(props) {
  const {
    handleSubmit,
    billingType,
    submitting,
    submitSucceeded,
    handleCartAbandonment,
    error,
    offer,
  } = props;
  const selectedBillingTypeFields = getPaymentMethodFormFields(props);

  const dispatch = useDispatch();

  useEffect(() => {
    if (offer?.type === "subscription") {
      dispatch(change("payment", "billingType", CREDIT_CARD));
    }
  }, [offer]);

  return (
    <Form
      onSubmit={handleSubmit}
      style={formStyle}
      wrapperStyle={formWrapperStyle}
      error={error}
      noMessages
    >
      {!submitSucceeded && (
        <RouteLeavingGuard
          onConfirmHandler={handleCartAbandonment}
          header="Certeza que deseja sair?"
          content="Tem certeza que deseja desistir da compra?"
        />
      )}

      {submitting ? (
        <div style={spinnerWrapper}>
          <Spinner wrapped />
        </div>
      ) : (
        <>
          {!billingType && <BillingTypeField {...props} />}

          {selectedBillingTypeFields}
        </>
      )}
    </Form>
  );
}
