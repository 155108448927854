import { css } from "glamor";
import React from "react";
import { IoLogoWhatsapp } from "react-icons/io";
import { spacing } from "./theme";

const linkWrapper = css({
  position: "fixed",
  bottom: 20,
  right: 20,
  zIndex: 2,
  width: 48,
  height: 48,
  backgroundColor: "white",
  borderRadius: "50%",
  boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.15)",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
});

const linkMargin = { marginTop: spacing.xs };
const iconStyle = { color: "#00E67A", width: 28, height: 28 };

export default function WhatsappLink({ phone }) {
  return (
    <div className={linkWrapper}>
      <a
        href={`https://api.whatsapp.com/send/?phone=${phone}`}
        target="_blank"
        style={linkMargin}
      >
        <IoLogoWhatsapp style={iconStyle} />
      </a>
    </div>
  );
}
