export const weekdays = [
  "sunday",
  "monday",
  "tuesday",
  "wednesday",
  "thursday",
  "friday",
  "saturday",
];

function isWeekdayIncluded(weekdayIndex, targetWeekdays) {
  return targetWeekdays.includes(weekdays[weekdayIndex]);
}

export function findDateByTargetWeekdays(targetWeekdays, daysAmount) {
  const currentDate = new Date();
  if (targetWeekdays.length === 0) return currentDate;

  const todayWeekdayIndex = currentDate.getDay();
  const isTodayIncluded = isWeekdayIncluded(todayWeekdayIndex, targetWeekdays);
  const upperLoopLimit = isTodayIncluded ? daysAmount - 1 : daysAmount;

  let matchedDaysCount = 0;
  while (matchedDaysCount < upperLoopLimit) {
    currentDate.setDate(currentDate.getDate() + 1);
    const weekdayIndex = currentDate.getDay();

    if (isWeekdayIncluded(weekdayIndex, targetWeekdays)) {
      matchedDaysCount++;
    }
  }

  return currentDate;
}
