import React from "react";
import CourseList from "../courses/CourseList";
import { Spinner } from "../library";

export default function CoursesData(props) {
  const { loading, courseCategories, justOwned, justNotOwned } = props;

  return loading ? (
    <Spinner wrapped />
  ) : (
    <>
      {courseCategories && (
        <CourseList
          courseCategories={courseCategories}
          justNotOwned={justNotOwned}
          justOwned={justOwned}
        />
      )}
    </>
  );
}
