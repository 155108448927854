import { connect } from "react-redux";
import { selectHomePageBanners } from "../../selectors/bannerSelectors";
import {
  getCourseCategoriesForList,
  getCourseCategoriesWithUserCourse,
} from "../../selectors/configSelectors";
import Home from "./Home";
import { hasActiveCourseUser } from "../../selectors/coursesUserSelectors";
import { getCurrentUser } from "../../selectors/userSelectors";

function mapStateToProps(state) {
  const hasActiveEnrollment = hasActiveCourseUser(state);
  const courseCategories = hasActiveEnrollment
    ? getCourseCategoriesWithUserCourse(state)
    : getCourseCategoriesForList(state);

  return {
    user: getCurrentUser(state),
    loading: state.coursesList.loading,
    banners: selectHomePageBanners(state),
    courseCategories,
    hasActiveEnrollment,
  };
}

export default connect(mapStateToProps)(Home);
