import { css } from "glamor";
import React from "react";
import { toBRLCurrency } from "../../utils/formatCurrency";
import SearchCouponFormContainer from "../coupons/SearchCouponFormContainer";
import {
  Button,
  Link,
  Spinner,
  Icon,
  colors,
  spacing,
  styles,
} from "../library";
import PaymentFormContainer from "../payments/PaymentFormContainer";
import CourseCheckoutEmptyOffer from "./CourseCheckoutEmptyOffer";
import CheckoutLeadFormContainer from "./CheckoutLeadFormContainer";
import Logo from "../library/images/logo-alternative.svg";
import SecurityBadgeAlternative from "../library/images/security-badge-alternative.svg";
import CourseCheckoutDetails from "./CourseCheckoutDetails";
import GuaranteeBadge from "../library/images/guarantee-badge.svg";
import { makeIsMobile } from "../../utils/useScreenWidth";

const DISCOUNT_BLUE = "#005EA1";
const DISCOUNT_DEGRADE_GREEN = "#13DAAC";

const container = css({
  background: "#D0D4DC",
  minHeight: "100vh",
  height: "100%",
  display: "flex",
  flexDirection: "column",
  flexWrap: "wrap",
  justifyContent: "center",
  alignItems: "center",
  padding: spacing.m,
});
const flexContent = css({
  width: "100%",
  maxWidth: "960px",
  display: "flex",
  flexWrap: "wrap",
});
const header = css(flexContent, {
  padding: `0px ${spacing.m}`,
  marginBottom: spacing.s,
  justifyContent: "space-between",
});
const content = css(flexContent, {
  justifyContent: "center",
  boxShadow: "0px 4px 44px 0px #00000026",
  borderRadius: spacing.m,
  overflow: "hidden",
});
const courseClass = css({
  flex: 1,
  minWidth: "350px",
  background: "#F0F1F6",
  display: "flex",
  flexDirection: "column",
  "@media(max-width: 739px)": {
    order: 1,
  },
});
const paymentClass = css({
  flex: 1,
  minWidth: "350px",
  background: colors.white,
  overflow: "hidden",
  display: "flex",
  flexDirection: "column",
  "@media(max-width: 739px)": {
    order: 2,
  },
});
const courseInfoWrapper = css({
  position: "relative",
  paddingBottom: "44.15%",
  height: 0,
  width: "100%",
  overflow: "hidden",
});
const courseInfoBaseClass = css({
  position: "absolute",
  top: 0,
  left: 0,
  bottom: 0,
  right: 0,
  backgroundSize: "cover !important",
  backgroundRepeat: "no-repeat !important",
  backgroundPosition: "center !important",
  display: "flex",
  alignItems: "end",
  padding: spacing.m,
});
const courseContentClass = css({
  flex: 1,
  display: "flex",
  padding: `${spacing.m} ${spacing.l}`,
  flexDirection: "column",
});
const couponWrapper = css(styles.shadow, {
  background: colors.white,
  marginTop: spacing.xxl,
  marginBottom: spacing.m,
  padding: spacing.s,
  borderRadius: 10,
  textAlign: "center",
});
const couponValue = {
  color: colors.successDark,
};
const resetedPadding = {
  padding: 0,
};
const hideStyle = {
  display: "none",
};
const backStyle = {
  textAlign: "center",
  marginTop: spacing.m,
  marginBottom: spacing.l,
  fontSize: 17,
};
const offerContainer = css({
  position: "relative",
  borderRadius: 10,
  paddingRight: 120,
  ":hover": {
    borderColor: colors.primary,
    borderSize: 2,
  },
  "& + &": {
    marginBottom: spacing.s,
  },
});
const rightPaddingReset = {
  paddingRight: 0,
};
const guaranteeClass = css({
  height: 120,
  width: 120,
  position: "absolute",
  right: 0,
  top: 0,
  backgroundImage: `url(${GuaranteeBadge})`,
  backgroundRepeat: "no-repeat",
  backgroundPosition: "right center",
  backgroundSize: "contain",
});
const centerContent = {
  justifyContent: "center",
};
const shadowLess = {
  boxShadow: "none",
  borderRadius: 0,
};
const discountWrapper = css({
  display: "flex",
  justifyContent: "center",
  margin: `${spacing.s} 0`,
});
const discountButtonStyle = {
  color: DISCOUNT_BLUE,
  background: `linear-gradient(white, white) padding-box, linear-gradient(to right, ${DISCOUNT_BLUE}, ${DISCOUNT_DEGRADE_GREEN}) border-box`,
  borderRadius: spacing.xs,
  border: "1px solid transparent",
  fontSize: 14,
  padding: 18,
};
const bookmarkWrapper = {
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
};
const priceTag = {
  marginLeft: spacing.s,
  marginRight: spacing.xs,
};
const bookmarkClass = css({
  userSelect: "none",
  whiteSpace: "pre-wrap",
  height: "0px",
  width: "212px",
  padding: "0px !important",
  textAlign: "center",
  fontSize: 12,
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  border: "20px solid red",
  borderRadius: "0px",
  lineHeight: 1.2,
  paddingTop: "12px",
  borderColor: "#005EA1",
  borderLeft: "12px solid transparent",
  color: colors.white,
  ":before": {
    boxSizing: "border-box",
  },
  ":after": {
    boxSizing: "border-box",
  },
});

export default function CourseCheckout(props) {
  const {
    user,
    leadCreated,
    loading,
    coupon,
    offer,
    wasPreviousStudent,
    discountConfirmed,
    confirmDiscountHandler,
    submitSucceeded,
    billingType,
  } = props;

  const isMobile = makeIsMobile();
  const hideDetails = submitSucceeded || (offer?.type == "one_time" && billingType == "CREDIT_CARD");
  const succededCreditCard = submitSucceeded && billingType == "CREDIT_CARD";

  const courseInfoClass = css(courseInfoBaseClass, {
    background: `url(${offer?.imageUrl || offer?.meta?.course?.coverImageUrl
      }), ${colors.primary}`,
  });

  return (
    <div className={container}>
      {loading ? (
        <Spinner wrapped />
      ) : (
        <>
          <div className={css(header, succededCreditCard && centerContent)}>
            <Link to="/" component="img" src={Logo} width="180px" />
            {!succededCreditCard && (
              <img src={SecurityBadgeAlternative} width="180px" />
            )}
          </div>

          <div className={css(content, succededCreditCard && shadowLess)}>
            <div className={css(paymentClass, billingType && resetedPadding)}>
              {(user || leadCreated) && !!offer ? (
                <PaymentFormContainer
                  offer={offer}
                  confirmDiscount={confirmDiscountHandler}
                  discountConfirmed={discountConfirmed}
                />
              ) : (
                <CheckoutLeadFormContainer />
              )}
            </div>

            <div className={css(courseClass, hideDetails && hideStyle)}>
              <div className={courseInfoWrapper}>
                <div className={courseInfoClass} />
              </div>

              <div className={courseContentClass}>
                {offer && (
                  <div
                    className={css(
                      offerContainer,
                      isMobile && rightPaddingReset,
                      offer?.type === "subscription" && { paddingRight: 0, paddingBottom: spacing.m }
                    )}
                  >
                    <h3>{offer.name}</h3>
                    <p>{offer.description}</p>
                    {!isMobile && offer?.type === "one_time" && <div className={guaranteeClass} />}
                  </div>
                )}

                {!offer && <CourseCheckoutEmptyOffer />}

                <div>
                  {
                    offer?.type === "one_time" && (
                      <SearchCouponFormContainer offer={offer} />
                    )
                  }

                  {coupon && (
                    <div className={couponWrapper}>
                      <h3>CUPOM: {coupon.code}</h3>

                      <p>
                        <strong style={couponValue}>
                          {coupon.couponType == "percent"
                            ? `${coupon.value}%`
                            : toBRLCurrency(coupon.value)}
                        </strong>{" "}
                        de desconto!
                      </p>
                    </div>
                  )}
                </div>

                {!discountConfirmed &&
                  wasPreviousStudent &&
                  !!offer?.meta?.course?.resellDiscount && (
                    <div className={discountWrapper}>
                      <div style={bookmarkWrapper}>
                        <div className={bookmarkClass}>
                          <Icon name="tag" size="large" style={priceTag} />
                          {`Desconto de ${offer.meta.course.resellDiscount}% para \n alunos disponível!`}
                        </div>
                      </div>

                      <Button
                        circular={false}
                        style={discountButtonStyle}
                        onClick={confirmDiscountHandler}
                      >
                        Aplicar
                      </Button>
                    </div>
                  )}
                <CourseCheckoutDetails {...props} />
              </div>
            </div>
          </div>

          {!succededCreditCard &&
            (user ? (
              <Link to="/" style={backStyle}>
                Ir para a home
              </Link>
            ) : (
              <Link href="https://t2.com.br/cursos" style={backStyle}>
                Ir para o site
              </Link>
            ))}
        </>
      )}
    </div>
  );
}
