import { css } from "glamor";
import React, { useEffect } from "react";
import { connect } from "react-redux";
import { fetchBanners } from "../processes/bannerProcesses";
import { fetchConfigs } from "../processes/configProcesses";
import { fetchContents } from "../processes/contentProcesses";
import { fetchCourses } from "../processes/courseProcesses";
import { fetchLessons } from "../processes/lessonProcesses";
import { fetchLessonsUsers } from "../processes/lessonsUserProccess";
import { fetchLevels } from "../processes/levelProcesses";
import { fetchLikes } from "../processes/likeProcesses";
import { fetchPromotions } from "../processes/promotionProcesses";
import { fetchReports } from "../processes/reportProcesses";
import { fetchCourseAnnotations } from "../processes/annotationProcesses";
import { getSession, sessionValidate } from "../processes/sessionProcesses";
import { getCurrentUser } from "../selectors/userSelectors";
import createImageFromInitials from "../utils/createImageFromInitials";
import NotifierContainer from "./notifier/NotifierContainer";
import OnboardingContainer from "./onboarding/OnboardingContainer";
import TermsContainer from "./terms/TermsContainer";
import { ActionCableProvider } from "react-actioncable-provider";
import { fetchViews } from "../processes/viewProcesses";
import { syncChatleadContact } from "../processes/chatleadProcesses";
import { fetchStudyGoals } from "../processes/studyGoalProcesses";

const container = css({
  width: "100%",
  minHeight: "100vh",
});

function LMS(props) {
  const { dispatch, currentCourseId, currentUser } = props;
  const { headers } = getSession();
  const url = `${process.env.CABLE_URL}/cable?access-token=${headers["access-token"]}&client=${headers?.client}&uid=${headers?.uid}`;

  useEffect(() => {
    sessionValidate(dispatch).then(() => {
      fetchConfigs(dispatch).then(() => {
        fetchCourses(dispatch);
        fetchBanners(dispatch);
        fetchPromotions(dispatch);
        fetchContents(dispatch);
        fetchLikes(dispatch);
        fetchViews(dispatch);
        fetchStudyGoals(dispatch);
        syncChatleadContact();
      });
    });
  }, [dispatch]);

  useEffect(() => {
    if (currentCourseId) {
      fetchLevels(dispatch, currentCourseId);
      fetchLessons(dispatch, currentCourseId);
      fetchLessonsUsers(dispatch, currentCourseId);
      fetchReports(dispatch, currentCourseId);
      fetchCourseAnnotations(dispatch, currentCourseId);
    }
  }, [dispatch, currentCourseId]);

  useEffect(() => {
    if (currentUser?.hasAvatar == false && !currentUser?.hasDefaultAvatar) {
      dispatch({
        type: "USER_DEFAULT_AVATAR_SETTED",
        id: currentUser.id,
        defaultAvatar: createImageFromInitials(500, currentUser.name),
      });
    }
  }, [dispatch, currentUser]);

  return (
    <div id="LMS" className={container}>
      <ActionCableProvider url={url}>
        <TermsContainer />
        <OnboardingContainer />
        <NotifierContainer />
        {props.children}
      </ActionCableProvider>
    </div>
  );
}

function mapStateToProps(state) {
  return {
    currentCourseId: state.currentCourseId,
    currentUser: getCurrentUser(state),
  };
}

export default connect(mapStateToProps)(LMS);
