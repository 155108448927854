import React from "react";
import { Page, spacing } from "../library";
import CoursesData from "../courses/CoursesData";
import ContinueContainer from "../continue/ContinueContainer";
import GreetingContainer from "../greeting/GreetingContainer";
import Banners from "../banners/Banners";

const bannersWrapper = {
  marginBottom: spacing.l,
};

export default function Home(props) {
  const { banners, hasActiveEnrollment, user } = props;

  return (
    <Page title={<GreetingContainer />}>
      {!!banners.length && (
        <Banners wrapperClass={bannersWrapper} banners={banners} />
      )}

      <ContinueContainer />

      <CoursesData justOwned={hasActiveEnrollment} {...props} />
    </Page>
  );
}
