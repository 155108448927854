import { css } from "glamor";
import React from "react";
import { spacing } from "../library";
import Carousel from "../library/Carousel";
import BannerContainer from "./BannerContainer";

const baseClass = css({
  borderRadius: spacing.xs,
  overflow: "hidden",
});

export default function Banners({ banners, wrapperClass }) {
  return !!banners?.length ? (
    <div className={css(baseClass, wrapperClass)}>
      <Carousel showIndicators={false} showThumbs={false} showStatus={false}>
        {banners.map((banner) => (
          <BannerContainer key={`banner${banner.id}`} banner={banner} />
        ))}
      </Carousel>
    </div>
  ) : null;
}
