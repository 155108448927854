import { connect } from "react-redux";
import { getCourseCategoriesWithBuyableCourses } from "../../selectors/configSelectors";
import { getCurrentUser } from "../../selectors/userSelectors";
import Store from "./Store";
import { selectStorePageBanners } from "../../selectors/bannerSelectors";

function mapStateToProps(state) {
  return {
    user: getCurrentUser(state),
    loading: state.coursesList.loading,
    courseCategories: getCourseCategoriesWithBuyableCourses(state),
    banners: selectStorePageBanners(state),
  };
}

export default connect(mapStateToProps)(Store);
